

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCitationScans } from '../../store/actions/citationActions';
import CitationDocument from './CitationDocument';
import { IRootState } from '../../interfaces/store';
import { ICitation, ICitationWithMeta } from '../../interfaces/model/MSHAPCT/citation.interface';
import { useParams } from 'react-router';
import { IMine } from '../../interfaces/model/MSHAPCT/mine.interface';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { validText } from '../../utils/validation';
import { isValidDateSQL } from '../../utils/date-utils';
interface IScanResultsProps {

}

const ScanResults: React.FC<IScanResultsProps> = (props) => {

    const [Citations, setCitations] = useState<ICitationWithMeta[]>([]);
    const { minepk } = useParams() as any;
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [mine, setMine] = useState<IMine | undefined>(undefined);
    const citation_form_data = useSelector(
        (state: IRootState) => state.main.citation_form_data
    );

    const mines = useSelector(
        (state: IRootState) => state.main.mines
    );

    useEffect(() => {
        const matchedMine = Object.values(mines).find(x => x.pk === Number(minepk));
        if (matchedMine) {
            setMine(matchedMine);
        }
    }, [mines])

	const nullableDateStr = (dt: any) => {

		if (isValidDateSQL(dt)){
			return new Date(dt);
		}
		else{
			return new Date(1970, 0, 1);
		}
	}

	const validDateOrNull = (dt: any) => {

		if (isValidDateSQL(dt)){
			return new Date(dt);
		}
		else{
			return null;
		}
	}

    useEffect(() => {
        console.log("citation_form_data" , citation_form_data);
        const convertedCitations = [] as ICitation[]

        citation_form_data.forEach((cfd) => {
            console.log("cfd", cfd)

            console.log("mine ad submittal", mine);
            let adjustedMineId = cfd.f_7_Mine_ID
            if (mine?.mineId?.startsWith("0")){
                console.log("started with 0")
                let mineIdNumber = parseInt(mine?.mineId);
                console.log("mineIdNumber?", mineIdNumber);
                console.log("Number(cfd.f_7_Mine_ID)", Number(cfd.f_7_Mine_ID));
                if (mineIdNumber === Number(cfd.f_7_Mine_ID)){
                    adjustedMineId = mine?.mineId;
                }
            }
			//console.log("set it to", nullableDateStr(cfd.f_14f_Date))
            const citation: ICitationWithMeta = {
                dateIssued: new Date(cfd.f_1_Date),
                actionToTerminate: cfd.f_17_Action_To_Terminate,
                area: cfd.f_15_Area_Or_Equipment,
                arnumber: cfd.f_23_AR_Number,
				arName: cfd.f_22_AR_Name,
                citationNumber: cfd.f_3_Citation_Order_Number,
                condition: cfd.f_8_Condition_Or_Practice,
                eventNumber: cfd.f_20_Event_Number,
                gravityChance: cfd.f_10a_Gravity_Chance,
                gravityResultExpected: cfd.f_10b_Gravity_Result_Expected,
                initialAction: cfd.f_14_Initial_Action,
                initialCitationDate: validDateOrNull(cfd.f_14f_Date),
                initialCitationNumber: cfd.f_14e_Citation_Order_Number,
                negligence: cfd.f_11_Negligence,
                operatorName: cfd.f_5_Operator,
                personsAffected: cfd.f_10d_Number_Of_Persons_Affected,
                primaryOrMill: cfd.f_21_Primary_Or_Mill,
                sectionOfAct: cfd.f_9b_Section_Of_Act,
                sectionOfCfr: cfd.f_9c_Section_Of_CFR,
                seeContForm: cfd.f_8_See_Continuation_Form,
                servedTo: cfd.f_4_Served_To,
                significant: cfd.f_10c_Significant,
                terminationDate: validDateOrNull(cfd.f_18a_Terminated_Date),
                terminationDueDate: validDateOrNull(cfd.f_16a_Termination_Due_Date),
                typeOfAction: cfd.f_12_Type_Of_Action,
                typeOfInspection: cfd.f_19_Type_Of_Inspection,
                typeOfIssuance: cfd.f_13_Type_Of_Issuance,
                // userPaid?: false
                violationType: cfd.f_9a_Violation_Type,
                writtenNotice: cfd.f_8a_Written_Notice,
				mineFk: minepk,
                mineId: adjustedMineId
            }

            convertedCitations.push(citation);
            //             f_1_Date: "2020-01-15T11:00:00"
            // f_2_Time: 0
            // f_3_Citation_Order_Number: 9194537
            // f_4_Served_To: "Mike Middlemas"
            // f_5_Operator: "PEABODY GATEWAY NORTH MINING, LLC"
            // f_6_Mine: "GATEWAY NORTH MINE"
            // f_7_Mine_ID: 1103235
            // f_8_Condition_Or_Practice: "Accumulations of combustible material in the form of coal fines and belt pressings have been allowed to accumulate at the 3rd Panel North (Unit #1) conveyor belt take-up. The accumulations were located on the steel framing under the stationary roller measuring 8 feet in length, 18 inches in width, 7 inches in depth, with 5 1/2 feet in length of the belt coming in contact with the accumulations. The accumulations on the steel framing under the carriage roller measured 4 1/2 feet in length, 18 inches in width, 12 inches in depth with 2 feet in length of the belt coming in contact with the accumulations. The belt was immediately removed from service by mine management,"
            // f_8_See_Continuation_Form: false
            // f_8a_Written_Notice: false
            // f_9a_Violation_Type: 2
            // f_9b_Section_Of_Act: null
            // f_9c_Section_Of_CFR: "75.400"
            // f_10a_Gravity_Chance: 3
            // f_10b_Gravity_Result_Expected: 2
            // f_10c_Significant: true
            // f_10d_Number_Of_Persons_Affected: 1
            // f_11_Negligence: 3
            // f_12_Type_Of_Action: 0
            // f_13_Type_Of_Issuance: 1
            // f_14_Initial_Action: 0
            // f_14e_Citation_Order_Number: 0
            // f_14f_Date: "0001-01-01T00:00:00"
            // f_15_Area_Or_Equipment: null
            // f_16a_Termination_Due_Date: "2020-01-15T11:30:00"
            // f_16b_Termination_Due_Time: 0
            // f_17_Action_To_Terminate: "The accumulations were removed from the mine by shoveling."
            // f_18a_Terminated_Date: "2020-01-15T11:25:00"
            // f_18b_Terminated_Time: 0
            // f_19_Type_Of_Inspection: 1
            // f_20_Event_Number: "7006956"
            // f_21_Primary_Or_Mill: 0
            // f_22_AR_Name: "Brandon Lampley"
            // f_23_AR_Number: 25374
        })
        setCitations(convertedCitations)
    }, [citation_form_data])

    return (<div>
        {Citations.length > 0 ?
            <div style={{ display: 'flex', width: '100%', marginBottom: 16 }}>
                <div style={{ flex: 2 }}>
                    * Review the scans for accuracy and make changes, then click Save Results to save the data.
                        </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    <Button
                        onClick={() => {

							console.log("Citations", Citations);
							let hasErrors = false;

							Citations.forEach((citation) => {
								if (!validText(citation.citationNumber)){
									hasErrors = true;
								}
								if (!isValidDateSQL(citation.initialCitationDate)){
								//	hasErrors = true;
								}
								if (!isValidDateSQL(citation.terminationDate)){
								//	hasErrors = true;
								}
								if (!isValidDateSQL(citation.terminationDueDate)){
								//	hasErrors = true;
								}
								if (!isValidDateSQL(citation.dateIssued)){
									hasErrors = true;
								}
							});

							if (hasErrors){
								enqueueSnackbar('Form validation error', { variant: 'error' })
							}
							else{
								dispatch(saveCitationScans({
                                    Citations,
                                    CitationItems: Citations 
                                }, () => {
									enqueueSnackbar('Citations Uploaded', { variant: 'success' })
									history.push(`/Mines/${minepk}/Citations/`);
								}, () => {
									enqueueSnackbar('Citation save error, is the citation being uploaded to the correct mine?', { variant: 'error' })
									history.push(`/Mines/${minepk}/Citations/`);			
								}))
							}

                        }}
                        variant="outlined"
                        size="small"
                    >
                        Save Results
            </Button>
                </div>

            </div> : <div>
                        There were no scan results, please try to upload again.
                </div>
        }
        {Citations.map((citation: ICitationWithMeta, index: number) => {
            return (
                <CitationDocument
                    key={index}
                    mine={mine}
                    index={index}
                    citation={citation}
                    mode={'edit'}
                    handleCitationChange={(citation: ICitationWithMeta, iindex: any) => {
                        const newCitations = Citations;
                        newCitations[iindex] = citation;
                        setCitations(newCitations);
                    }}
                />
            )
        })}

    </div>)

    // <CitationDocument />
}

export default ScanResults